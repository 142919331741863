<template>
    <div class="branch-dropdown">
        <!-- Dropdown button -->
        <div @click="toggleDropdown" class="dropdown-toggle">
            <span>{{ selected.name }}</span>
            <span :class="`mdi ${icon}`"></span>
        </div>

        <!-- Dropdown options -->
        <div v-if="isOpen" class="dropdown-menu">
            <div class="dropdown-item" v-for="option in options" :key="option.id" @click="selectItem(option)">
                {{ option.name }}
                <span v-if="option.isPartner" class="partner-tag">partner</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {  // This is what is passed via v-model
            type: Object,
            default: () => ({ name: '', id: '' })
        },
        options: {
            type: Array,
            default: () => []
        },
        icon: {
            type: String,
            default: 'mdi-chevron-down'
        }
    },
    data() {
        return {
            isOpen: false,
            selected: this.value  // Initialize with the v-model value
        };
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectItem(item) {
            this.selected = item;  // Update the selected item
            this.$emit('input', item);  // Emit the selection to parent via v-model
            this.isOpen = false;
        }
    },
    watch: {
        value(newValue) {
            if (newValue !== this.selected) {
                this.selected = newValue;  // Ensure selected reflects the v-model value
            }
        }
    }
};
</script>

<style scoped>
/* Adjust the container to have the correct position */
.branch-dropdown {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 240px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
  
}

.dropdown-toggle {
    width: 100%;
    padding: 4px;
    background-color: #333;
    color: white;
    border-radius: 4px;
    z-index: 3;
    border: 1px solid #444;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    text-align: center;
}


.dropdown-menu {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    /* transform: translateX(-20%); */
    width: 100%;
    background-color: #000;
    border: 1px solid #444;
    border-radius: 4px;
    z-index: 3;
}

.dropdown-item {
    padding: 8px 16px;
    color: white;
    cursor: pointer;
    font-size: 12px;
    text-align: left;
}

.dropdown-item:hover {
    background-color: #555;
    /* Hover color */
}

.partner-tag {
    background: white;
    color: black;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 0.8em;
    margin-left: 4px;
    font-weight: bold;
}
</style>
