<template>
    <div class="sidebar-wrapper">
        <div>
            <Close/>
            <div class="level is-mobile title">
                <div class="level-left">
                    <div class="level-item">
                        <div>
                            MAP LAYERS<br>
                            <p class="sub-title">DATA OVERLAYS</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <!-------------------------- End title ------------------------>
        <div>
            <div class="level is-mobile" style="margin-top: 20px;">
                <div class="level-left">
                    <div class="level-item name">
                        Branches
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item switch">
                        <b-field>
                            <b-switch v-model="$store.state.branches" @input="toggleMarkers(branch_markers)"></b-switch>
                        </b-field>
                    </div>
                </div>   
            </div>
            <div class="level is-mobile" style="margin-top: 20px;">
                <div class="level-left">
                    <div class="level-item name">
                        Outplant Sites
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item switch">
                        <b-field>
                            <b-switch v-model="$store.state.restoredReefs" @input="toggleLayers('outplantSite-layer')"></b-switch>
                        </b-field>
                    </div>
                </div>   
            </div>
            <div class="level is-mobile">
                <div class="level-left">
                    <div class="level-item name">
                        Coral Nurseries
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item switch">
                        <b-field>
                            <b-switch v-model="$store.state.nurseries" @input="toggleMarkers(nursery_markers)"></b-switch>
                        </b-field>
                    </div>
                </div>
            </div>
            <div class="level is-mobile">
                <div class="level-left">
                    <div class="level-item name">
                        ReefOS Sensors
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item switch">
                        <b-field>
                            <b-switch v-model="$store.state.sensors" @input="toggleMarkers(sensor_markers)"></b-switch>
                        </b-field>
                    </div>
                </div>
            </div>
            <div class="level is-mobile">
                <div class="level-left">
                    <div class="level-item name">
                        Drone
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item switch">
                        <b-field>
                            <b-switch v-model="$store.state.drone" @input="toggleDroneLayers()"></b-switch>
                        </b-field>
                    </div>
                </div>
            </div>
            <b-collapse v-if="$store.state.drone">
                <div class="attribution" style="margin-top: -15px; margin-bottom: 10px; width: 400px;"> 
                    <b-icon icon="information-outline" size="is-small" style="margin-left: 5px;"></b-icon>
                    Data collected in collaboration with The Nature Conservancy, Grupo Punta Cana and Fundemar, Dominican Republic. 
                </div>
            </b-collapse>
            <!-- <div class="level is-mobile">
                <div class="level-left">
                    <div class="level-item name">
                        HD Satellite
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item switch">
                        <b-field>
                            <b-switch v-model="$store.state.satellite" @input="toggleLayers('satellite-layer')"></b-switch>
                        </b-field>
                    </div>
                </div>
            </div>
            <b-collapse v-if="$store.state.satellite">
                <div class="attribution" style="margin-top: -15px; margin-bottom: 10px;"> 
                    <b-icon icon="information-outline" size="is-small" style="margin-left: 5px;"></b-icon>
                    Data provided by Maxar WorldView 2
                </div>
            </b-collapse> -->
            <div class="level is-mobile">
                <div class="level-left">
                    <div class="level-item name">
                        Basemap Imagery
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item switch">
                        <b-field>
                            <b-switch v-model="$store.state.basemap" @input="toggleStyle()"></b-switch>
                        </b-field>
                    </div>
                </div>
            </div>
            <!-- <div class="level is-mobile">
                <div class="level-left">
                    <div class="level-item name">
                        Bathymetry
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item switch">
                        <b-field>
                            <b-switch v-model="$store.state.bathymetry" @input="toggleBathymetry()"></b-switch>
                        </b-field>
                    </div>
                </div>
            </div>
            <b-collapse v-if="$store.state.bathymetry" v-model="$store.state.openBathymetry">
                <div class="attribution" style="margin-top: -15px; margin-bottom: 10px;"> 
                    <b-icon icon="information-outline" size="is-small" style="margin-left: 5px;"></b-icon>
                    Data provided by Allen Coral Atlas
                </div>
            </b-collapse>
            <div class="level is-mobile">
                <div class="level-left">
                    <div class="level-item name">
                        Geomorphic Class
                        <div @click="$store.state.openGeomorphic = !$store.state.openGeomorphic" style="cursor: pointer">
                            <b-icon :icon="$store.state.openGeomorphic ? 'menu-down' : 'menu-left'"></b-icon>
                        </div>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item switch">
                        <b-field>
                            <b-switch v-model="$store.state.geomorphic" @input="toggleGeomorphic()"></b-switch>
                        </b-field>
                    </div>
                </div>
            </div>
            <b-collapse v-model="$store.state.openGeomorphic">
                <div class="attribution" style="margin-top: -15px;"> 
                    <b-icon icon="information-outline" size="is-small" style="margin-left: 5px;"></b-icon>
                    Data provided by Allen Coral Atlas
                </div>
                <div class="sub geomorphic clearfix">
                    <div style="float: left; width: 50%;">
                        <div class="legend">
                            <div style="float: left; background-color: #288471" class="square"></div>
                            <div style="float: left;">Reef Slope</div>
                            <div style="clear: both;"></div>
                        </div>
                        <div class="legend">
                            <div style="float: left; background-color: #10BDA6" class="square"></div>
                            <div style="float: left;">Sheltered Reef</div>
                            <div style="clear: both;"></div>
                        </div>
                        <div class="legend">
                            <div style="float: left; background-color: #614272" class="square"></div>
                            <div style="float: left;">Reef Crest</div>
                            <div style="clear: both;"></div>
                        </div>
                        <div class="legend">
                            <div style="float: left; background-color: #92739D" class="square"></div>
                            <div style="float: left;">Outer Reef Flat</div>
                            <div style="clear: both;"></div>
                        </div>
                        <div class="legend">
                            <div style="float: left; background-color: #C5A7CB" class="square"></div>
                            <div style="float: left;">Inner Reef Flat</div>
                            <div style="clear: both;"></div>
                        </div>
                        <div class="legend">
                            <div style="float: left; background-color: #FBDEFB" class="square"></div>
                            <div style="float: left;">Terrestial Reef Flat</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                    <div style="float: left; width: 50%;">
                        <div class="legend">
                            <div style="float: left; background-color: #CD6812" class="square"></div>
                            <div style="float: left;">Plateau</div>
                            <div style="clear: both;"></div>
                        </div>
                        <div class="legend">
                            <div style="float: left; background-color: #E69113" class="square"></div>
                            <div style="float: left;">Small Reef</div>
                            <div style="clear: both;"></div>
                        </div>
                        <div class="legend">
                            <div style="float: left; background-color: #FFBA15" class="square"></div>
                            <div style="float: left;">Patch Reef</div>
                            <div style="clear: both;"></div>
                        </div>
                        <div class="legend">
                            <div style="float: left; background-color: #BEFBFF" class="square"></div>
                            <div style="float: left;">Back Reef SLope</div>
                            <div style="clear: both;"></div>
                        </div>
                        <div class="legend">
                            <div style="float: left; background-color: #77D0FC" class="square"></div>
                            <div style="float: left;">Shallow Lagoon</div>
                            <div style="clear: both;"></div>
                        </div>
                        <div class="legend">
                            <div style="float: left; background-color: #2CA2F9" class="square"></div>
                            <div style="float: left;">Deep Lagoon</div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                    
                </div>
            </b-collapse>
            <div style="clear: both;"></div>
            <div class="level is-mobile">
                <div class="level-left">
                    <div class="level-item name">
                        Benthic Class
                        <div @click="$store.state.openBenthic = !$store.state.openBenthic" style="cursor: pointer">
                            <b-icon :icon="$store.state.openBenthic ? 'menu-down' : 'menu-left'"></b-icon>
                        </div>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item switch">
                        <b-field>
                            <b-switch v-model="$store.state.benthic" @input="toggleAllBenthic()"></b-switch>
                        </b-field>
                    </div>
                </div>
            </div>
            <b-collapse v-model="$store.state.openBenthic">
                <div class="attribution" style="margin-top: -15px;">
                    <b-icon icon="information-outline" size="is-small" style="margin-left: 5px;"></b-icon>
                    Data provided by Allen Coral Atlas
                </div>
                <div class="sub benthic clearfix">
                    <div class="level is-mobile" style="margin-bottom: 5px;">
                        <div class="level-left">
                            <div class="level-item">
                                <div class="coral square"></div><p>Coral/Algae</p>
                            </div>
                        </div>
                        <div class="level-right">
                            <div class="level-item">
                                <b-field>
                                    <b-checkbox v-model="$store.state.visBenthic[3]" @input="toggleBenthic(3)"></b-checkbox>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="level is-mobile" style="margin-bottom: 5px;">
                        <div class="level-left">
                            <div class="level-item">
                                <div class="seagrass square"></div><p>Seagrass</p>
                            </div>
                        </div>
                        <div class="level-right">
                            <div class="level-item">
                                <b-field>
                                    <b-checkbox v-model="$store.state.visBenthic[4]" @input="toggleBenthic(4)"></b-checkbox>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="level is-mobile" style="margin-bottom: 5px;">
                        <div class="level-left">
                            <div class="level-item">
                                <div class="microalgal square"></div><p>Microalgal Mats</p>
                            </div>
                        </div>
                        <div class="level-right">
                            <div class="level-item">
                                <b-field>
                                    <b-checkbox v-model="$store.state.visBenthic[5]" @input="toggleBenthic(5)"></b-checkbox>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="level is-mobile" style="margin-bottom: 5px;">
                        <div class="level-left">
                            <div class="level-item">
                                <div class="rock square"></div><p>Rock</p>
                            </div>
                        </div>
                        <div class="level-right">
                            <div class="level-item">
                                <b-field>
                                    <b-checkbox v-model="$store.state.visBenthic[1]" @input="toggleBenthic(1)"></b-checkbox>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="level is-mobile" style="margin-bottom: 5px;">
                        <div class="level-left">
                            <div class="level-item">
                                <div class="rubble square"></div><p>Rubble</p>
                            </div>
                        </div>
                        <div class="level-right">
                            <div class="level-item">
                                <b-field>
                                    <b-checkbox v-model="$store.state.visBenthic[2]" @input="toggleBenthic(2)"></b-checkbox>
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="level is-mobile" style="margin-bottom: 5px;">
                        <div class="level-left">
                            <div class="level-item">
                                <div class="sand square"></div><p>Sand</p>
                            </div>
                        </div>
                        <div class="level-right">
                            <div class="level-item">
                                <b-field>
                                    <b-checkbox v-model="$store.state.visBenthic[0]" @input="toggleBenthic(0)"></b-checkbox>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
            </b-collapse>
            <div style="clear: both;"></div> -->
        </div>
    </div>
</template>

<script>
import Close from '@/components/utils/Close.vue'
import { eventBus } from '@/events.js'
import mapboxgl from 'mapbox-gl'

export default {
    props: ['depth', 'elevation', 'map', 'sensor_markers', 'nursery_markers', 'branch_markers', 'cursor'],
    components: {
        Close
    },
    data() {
        return {
            page: 'map',
        }
    },
    mounted() {
        eventBus.$on('resetLayers', () => {
            this.$store.state.restoredReefs = true;
            this.$store.state.nurseries = true;
            this.$store.state.sensors = true;
            this.$store.state.satellite = false;
            this.$store.state.drone = false;
            this.$store.state.bathymetry = false;
            this.$store.state.openBathymetry = false;
            this.$store.state.geomorphic = false;
            this.$store.state.openGeomorphic = false;
            this.$store.state.benthic = false;
            this.$store.state.openBenthic = false;
            this.$store.state.allBenthic = ["Sand", "Rock", "Rubble", "Coral/Algae", "Seagrass", "Microalgal Mats"];
            this.$store.state.visBenthic = [true, true, true, true, true, true];
            this.$store.state.filteredBenthic = ["Sand", "Rock", "Rubble", "Coral/Algae", "Seagrass", "Microalgal Mats"];
        })
    },
    methods: {
        toggleLayers(id) {
            const visibility = this.map.getLayoutProperty(id, 'visibility');
            // Toggle layer visibility by changing the layout object's visibility property.
            if (visibility === 'visible' || visibility === undefined) {
                this.map.setLayoutProperty(id, 'visibility', 'none');
            } else {
                this.map.setLayoutProperty(id, 'visibility', 'visible');
            }

        },
        toggleMarkers(array) {
            if (array[0]._element.style.visibility === 'visible') {
                array.forEach(marker => {
                    marker._element.style.visibility = 'hidden'
                })
            } else {
                array.forEach(marker => {
                    marker._element.style.visibility = 'visible'
                })
            }
        },
        toggleDroneLayers() {
            const targetPoint = new mapboxgl.LngLat(-149.799574, -17.476653);
            const mapBounds = this.map.getBounds();
            let makeVisible = false;

            // Check the current visibility of the drone layers
            const allLayers = this.map.getStyle().layers;
            allLayers.forEach((layer) => {
                if (layer.id.startsWith('drone-')) {
                    const visibility = this.map.getLayoutProperty(layer.id, 'visibility');
                    if (visibility === 'none') {
                        makeVisible = true;
                    }
                }
            });

            // Fly to the target point if making the drone layers visible
            // if (makeVisible && !mapBounds.contains(targetPoint)) {
            //     this.map.flyTo({ center: targetPoint, zoom: 14 }); // Adjust zoom level as needed
            // }

            // Toggle the visibility of the drone layers
            allLayers.forEach((layer) => {
                if (layer.id.startsWith('drone-')) {
                    const visibility = this.map.getLayoutProperty(layer.id, 'visibility');
                    const newVisibility = (visibility === 'visible') ? 'none' : 'visible';
                    this.map.setLayoutProperty(layer.id, 'visibility', newVisibility);
                }
            });
        },
        toggleSatellite() {
            console.log('satellite')
        },
        toggleBathymetry() {
            this.$store.state.openBathymetry = !this.$store.state.openBathymetry;
            if (this.$store.state.bathymetry) {
                this.map.setLayoutProperty('bathymetry', 'visibility', 'visible');
                this.map.setLayoutProperty('bathymetry-rendered', 'visibility', 'visible');
            } else {
                this.map.setLayoutProperty('bathymetry', 'visibility', 'none');
                this.map.setLayoutProperty('bathymetry-rendered', 'visibility', 'none');
            }
        },
        toggleGeomorphic() {
            this.$store.state.openGeomorphic = !this.$store.state.openGeomorphic;
            if (this.$store.state.geomorphic) {
                this.map.setLayoutProperty('geomorphic', 'visibility', 'visible');
                this.map.setLayoutProperty('geomorphic-rendered', 'visibility', 'visible');
            } else {
                this.map.setLayoutProperty('geomorphic', 'visibility', 'none');
                this.map.setLayoutProperty('geomorphic-rendered', 'visibility', 'none');
            }
        },
        toggleAllBenthic() {
            this.$store.state.openBenthic = !this.$store.state.openBenthic;
            if (this.$store.state.benthic) {
                this.map.setLayoutProperty('benthic', 'visibility', 'visible'); // vector sources all in same layer
                this.$store.state.allBenthic.forEach((name, index) => {
                    if (this.$store.state.visBenthic[index]) {
                        this.map.setLayoutProperty(`${name.replace(/\//g, "")}-rendered`, 'visibility', 'visible');
                    }
                })
            } else {
                this.map.setLayoutProperty('benthic', 'visibility', 'none');
                this.$store.state.allBenthic.forEach((name, index) => {
                    if (this.$store.state.visBenthic[index]) {
                        this.map.setLayoutProperty(`${name.replace(/\//g, "")}-rendered`, 'visibility', 'none');
                    }
                })
            }
        },
        toggleBenthic(index) {
            let name = this.$store.state.allBenthic[index];
            let viz = this.$store.state.visBenthic[index];
            
            if (viz) {
                this.$store.state.filteredBenthic.push(name);
                this.map.setFilter('benthic', ["all", ["match", ["get", "class"], this.$store.state.filteredBenthic, true, false]]);
                this.map.setLayoutProperty(`${name.replace(/\//g, "")}-rendered`, 'visibility', 'visible');
            } else {
                this.$store.state.filteredBenthic = this.$store.state.filteredBenthic.filter(e => e != name);
                if (this.$store.state.filteredBenthic.length == 0) {
                    this.map.setFilter('benthic', ["all", ["match", ["get", "class"], ["all"], true, false]]);
                } else {
                    this.map.setFilter('benthic', ["all", ["match", ["get", "class"], this.$store.state.filteredBenthic, true, false]]);
                }
                this.map.setLayoutProperty(`${name.replace(/\//g, "")}-rendered`, 'visibility', 'none');
            }
        },
        toggleStyle() {
            if (this.$store.state.basemap) {
                this.map.setStyle('mapbox://styles/drewjgray3d/cl9kdwr6y000715muk49hq3z8');
            } else {
                this.map.setStyle('mapbox://styles/drewjgray3d/cl9de4f5y000c15s0c4sgches');
            }
            // TODO: Reset all layers after toggling basemap by emitting global event
            
        }
    },
}
</script>

<style scoped>
.name {
  margin-left: 10px;
}
.switch {
    margin-right: 10px;
}

.benthic {
    padding: 10px;
}
.geomorphic {
    padding: 10px;
}
.legend {
    margin-bottom: 5px;
}
.square {
    width: 15px;
    height: 15px;
    border-radius: 1px;
    margin-right: 10px;
}
.coral {
    background-color: #FF6161;
}
.seagrass {
    background-color: #9BCC4F;
}
.microalgal {
    background-color: #668438;
}
.rock {
    background-color: #B19C3A;
}
.rubble {
    background-color: #E0D05E;
}
.sand {
    background-color: #FFFFBE;
}

</style>